// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser, LocalTokenGroup} from "@lezer/lr"
export const parser = LRParser.deserialize({
  version: 14,
  states: "-[OVQPOOO!PQPO'#C^O#POSO'#D[O#[OSO'#D[OOQO'#Ci'#CiO#gQPO'#DZO$dQPO'#CjO$iQPO'#CkOOQO'#DP'#DPOVQPO'#CpO$nQPO'#DOO%RQPO'#DlOOQO'#Co'#CoO%zQPO'#DlOOQO'#Cs'#CsO%iQPO'#DlOOQO'#Ct'#CtO%lQPO'#DlOOQO'#Cu'#CuOOQO'#Dl'#DlOOQO'#DO'#DOQOQPOOOOQO'#C`'#C`OOQO'#Ca'#CaOOQO'#Cb'#CbO&RQQO'#CcO&WQPO'#CgOOQO'#DQ'#DQOOQO,58x,58xOVQPO,59^OOOO'#Cv'#CvO'_OSO,59vOOQO,59v,59vO'jOSO,59vOOQO,59u,59uO'uQQO,59UO'zQPO,59VO(SQPO,59[O(dQPO,59[O(VQPO'#DmOOQO'#Cw'#CwO(iQPO,5:XOVQPO'#CxO)PQPO,5:[OVQPO'#CyO)eQPO,5:^OVQPO'#CzO)vQPO,5:`O*UQQO'#CdO*^QPO,58}O*cQQO,58}OOQO,59R,59RO*hQPO1G.xOOOO-E6t-E6tOOQO1G/b1G/bO*mQPO1G.pO+jQQO'#ClO+rQPO1G.qO,oQPO1G.qO,tQPO1G.vOOQO-E6u-E6uO-tQPO,59dOOQO,59d,59dOOQO-E6v-E6vO.[QPO,59eO.rQPO,59eOOQO,59e,59eOOQO-E6w-E6wO/WQPO,59fO0PQPO,59fO/nQPO,59fOOQO,59f,59fOOQO-E6x-E6xOOQO1G.i1G.iO0WQQO1G.iOOQO7+$d7+$dOOQO7+$[7+$[O0]QQO'#CmOOQO'#Cm'#CmO0eQQO,59WOOQO7+$]7+$]O0jQPO7+$]OOQO7+$b7+$bOOQO'#Cf'#CfO1gQPO7+$TOOQO,59X,59XO1lQQO1G.rOOQO<<Gw<<GwOOQO<<Go<<GoO1tQQO'#CnOOQO'#Cn'#CnO1|QPO7+$^OOQO,59Y,59YOOQO<<Gx<<Gx",
  stateData: "2R~OqOS~ORPO!PQO!SRO!USO!VUO!XVO!YXO~OufOvgOwhOxiO|jO~O!bmORQXoQX!PQX!SQX!UQX!VQX!XQX!YQX!eQX!gQX!iQX!^QX!cQX~PnO[nO!QnO!RpO~O[nO!QnO!TpO~OR}Xo}X!P}X!S}X!U}X!V}X!X}X!Y}X!e}X!g}X!i}X!^}X!c}X~PnORsO~ORtO~O!ezO!g|O!i!OOorX!crX~PVO!ezO!g|O!i!OOo!`X!^!`X!c!`X~PVO!g|O!i!OOo!`X!^!`X!c!`X~O!ezO~P%iOy!QO~OufOvgOwhOxiORZXoZX!PZX!SZX!UZX!VZX!XZX!YZX!eZX!gZX!iZX!^ZX!cZX~O[nO!QnO!R!WO~O[nO!QnO!T!WO~O!W!XO~O!Y!YO!_!ZO~O!^!]O!ezO!g|O!i!OO~PVO!^!]O~Oo!aa!e!aa!g!aa!i!aa!^!aa!c!aa~PVO!ezOo!da!g!da!i!da!^!da!c!da~O!g|Oo!fa!i!fa!^!fa!c!fa~O!i!OOo!ha!^!ha!c!ha~OzWX{XX~Oz!kO~O{!lO~O!c!mO~OR^io^i!P^i!S^i!U^i!V^i!X^i!Y^i!e^i!g^i!i^i!^^i!c^i~PnOy!oO![!pO~OR_io_i!P_i!S_i!U_i!V_i!X_i!Y_i!e_i!g_i!i_i!^_i!c_i~PnO!_!sO~Oe!tORdiodi!Pdi!Sdi!Udi!Vdi!Xdi!Ydi!edi!gdi!idi!cdi!^di~PnOola!ela!gla!ila!^la!cla~PVO!ezOoma!gma!ima!^ma!cma~PVO!ezOoma!gma!ima!^ma!cma~O!ezO!g|Oona!ina!^na!cna~PVO!g|Oona!ina!^na!cna~O!ezO~P/nOy!uO~O!Z!wO{aX~O{!xO~OR_qo_q!P_q!S_q!U_q!V_q!X_q!Y_q!e_q!g_q!i_q!^_q!c_q~PnOz!zO~Oy!{O!]!|O~O!Z#OO!^bX~O!^#PO~O",
  goto: ")U!iPP!jP!z!z!z!z#V#Y#]#`P#h!j!j#x#{$O$R$aP${%e%p%x%}&X&e&s'RPPP'_'e(PPPPPPPPP!j#hPPPPPPPPPPPPPPP(c(jPP(rP(yP)PmWOXYZmuwyz|!O!_!b!f[kPT!X!Z!]!sR!TjR!RiR!SiR!v!l]kPT!X!Z!]!smTOXYZmuwyz|!O!_!b!fR![tR!q!YR!}!xU]OXmQ!`zQ!c|R!g!OSYOmQwX`xYZuwy!_!b!fQ!_zQ!b|R!f!OUZOXm`xYZuwy!_!b!fQ!_zQ!b|R!f!OU_OXmQ!d|R!h!OUaOXmR!i!OVcOXmQoQQqRT!Voq^yYZuw!_!b!fR!^yb{YZ]uw!b!c!f!gR!a{b}YZ]_uw!f!g!hR!e}^!PYZ]_auwR!j!PQeOR!UmSYOmQuX`xYZuwy!_!b!fQ!_zQ!b|R!f!OQlPQrTQ!n!XQ!r!ZQ!t!]R!y!sSdOmRvX][OXmz|!OZ^OXm|!OX`OXm!OVbOXm",
  nodeNames: "⚠ CSSValueDefinition Literal Identifier ZeroOrMore OneOrMore ZeroOrOne Some Min Min Max OneOrMoreCommaSeparated String String PropertyReference DataType Range Start End OrderedSequence Group Required FunctionCall UnorderedSequence Subset Alternation",
  maxTerm: 71,
  nodeProps: [
    ["group", -5,4,5,6,7,11,"Quantifier"]
  ],
  skippedNodes: [0],
  repeatNodeCount: 5,
  tokenData: "(j~RnXY#PYZ#P]^#Ppq#Pqr#Urs#Zst#`vw#ewx#pxy#}yz$Sz{$X{|$^|}$k}!O$r!O!P&V!P!Q&V!Q![&[!^!_&u!`!a'S!a!b'X!c!}'^!}#O'w#P#Q'|#R#S%i#T#o'^#o#p(R#p#q(W#q#r(e%&x%&y$f~#UOq~~#ZOe~~#`O!S~~#eO|~~#hPvw#k~#pO!e~U#uP!PQ!`!a#xS#}O!WS~$SO!b~~$XO!c~~$^Ou~U$cPvQ%&x%&y$fS$kO!]SU$rO{S!UQU$wVRQxy%^}!O%i!Q![%i!c!}%i#R#S%i#T#o%i%&x%&y&QQ%aPyz%dQ%iORQQ%nURQxy%^}!O%i!Q![%i!c!}%i#R#S%i#T#o%iS&VO![SQ&[O!UQU&cURQySxy%^}!O%i!Q![&[!c!}%i#R#S%i#T#o%i~&zP!X~wx&}~'SO!V~~'XO!_~~'^Ow~U'eURQ!ZSxy%^}!O%i!Q![%i!c!}'^#R#S%i#T#o'^~'|O!Y~~(RO!^~~(WOx~~(]P!i~#p#q(`~(eO!g~~(jOz~",
  tokenizers: [1, 2, new LocalTokenGroup("!Q~RRrs[wxa#O#Pf~aO!T~~fO!R~~iRO;'Sr;'S;=`w;=`Or~wO!Q~~|P!Q~;=`<%lr~", 47, 12)],
  topRules: {"CSSValueDefinition":[0,1]},
  tokenPrec: 0
})
